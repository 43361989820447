











































































































































































import {namespace} from 'vuex-class';
import {Component, Prop, Vue} from 'vue-property-decorator';
import ShipArrival from '@/models/ShipArrival.model';
import {Booking} from '@/models/Booking.model';
import {SlotType} from '@/enums/SlotType.enum';
import Slot from '@/models/Slot.model';
import {BookingState} from '@/enums/BookingState.enum';
import {authStoreGetter} from '@/store/auth.store';
import User, {UserRole} from '@/models/User.model';
import {DateTime} from "luxon";
import {settingsStoreGetter} from "@/store/settings.store";
import {SlotSettings} from "@/interfaces/SlotSettings.interface";
import Voyage from "@/models/Voyage.model";
import ShipArrivalRepository from '@/api/repositories/ShipArrival.repository';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import Vessel from '@/models/Vessel.model';
import {saveAs} from 'file-saver';

const AuthStore = namespace('auth');
const SettingsStore = namespace('settings');
const shipArrivalRepository: ShipArrivalRepository = RepositoryFactory.get('shipArrival');

@Component({
  components: {
    ShipArrivalDetailsComponent: () => import(
      /* webpackChunkName: "ShipArrivalDetailsComponent" */
      '@/components/shipArrival/ShipArrivalDetails.component.vue'),
    ShipArrivalHistoryComponent: () => import(
      /* webpackChunkName: "ShipArrivalHistoryComponent" */
      '@/components/shipArrival/ShipArrivalHistory.component.vue'),
  }
})
export default class ShipArrivalTableComponent extends Vue {

  @AuthStore.Getter(authStoreGetter.CURRENT_USER)
  public currentUser!: User;

  @SettingsStore.Getter(settingsStoreGetter.SETTINGS)
  public settings!: SlotSettings;

  @Prop({default: () => []})
  public shipArrivals!: ShipArrival[];

  @Prop({default: ''})
  public searchValue!: string;

  @Prop({default: false})
  public disabledClick!: boolean;

  public selectedShipArrival: ShipArrival | null = null;
  public showDetailsDialog: boolean = false;
  public showHistoryDialog: boolean = false;
  public showDeletionConfirmDialog: boolean = false;

  public BookingState = BookingState;
  public UserRole = UserRole;
  private currentDateTime: DateTime = DateTime.utc();

  public tableHeaders = [{
    text: this.$t('SHIP_ARRIVAL.TABLE.VESSEL_NAME'),
    value: 'voyage.vessel.name',
    sortable: true
  }, {
    text: this.$t('SHIP_ARRIVAL.TABLE.TRAVEL_NUMBER_IMPORT'),
    value: 'voyage.voyageNoImport',
    sortable: true
  }, {
    text: this.$t('SHIP_ARRIVAL.TABLE.TRAVEL_NUMBER_EXPORT'),
    value: 'voyage.voyageNoExport',
    sortable: true
  }, {
    text: this.$t('SHIP_ARRIVAL.TABLE.ETA'),
    value: 'voyage.eta',
    sortable: true
  }, {
    text: this.$t('SHIP_ARRIVAL.TABLE.SHIP_OWNER'),
    value: 'shipOwner.name',
    sortable: true
  }, {
    text: this.$t('SHIP_ARRIVAL.TABLE.IMPORT'),
    value: 'import',
    sortable: true
  }, {
    text: this.$t('SHIP_ARRIVAL.TABLE.EXPORT'),
    value: 'export',
    sortable: true
  }, {
    text: this.$t('SHIP_ARRIVAL.TABLE.BOOKED_SLOTS'),
    value: 'bookedSlots',
    sortable: false
  }, {
    text: this.$t('SHIP_ARRIVAL.TABLE.STATUS'),
    value: 'status',
    sortable: false
  }, {
    text: this.$t('SHIP_ARRIVAL.TABLE.OPTIONS'),
    value: 'options',
    sortable: false,
    align: 'right'
  }];

  public created() {

    if (!this.$hasRole(UserRole.SHIP_OWNER)) {
      const historyChanges = {
        text: this.$t('SHIP_ARRIVAL.TABLE.HISTORY_CHANGES'),
        value: 'historyChanges',
        sortable: false
      };
      this.tableHeaders.splice(5, 0, historyChanges);
    }
  }

  public editShipArrival(shipArrival: ShipArrival) {
    this.$emit('edit-ship-arrival', shipArrival);
  }

  public deleteShipArrival(shipArrival: ShipArrival) {
    this.selectedShipArrival = shipArrival;
    this.showDeletionConfirmDialog = true;
  }

  public onDeletionConfirmed() {
    this.$emit('delete-ship-arrival', this.selectedShipArrival);
    this.showDeletionConfirmDialog = false;
  }

  public showDetails(shipArrival: ShipArrival) {
    if (this.disabledClick) {
      return;
    }
    this.selectedShipArrival = shipArrival;
    this.showDetailsDialog = true;
  }

  public showHistory(shipArrival: ShipArrival) {
    this.selectedShipArrival = shipArrival;
    this.showHistoryDialog = true;
  }

  public async closeDialog(reload: boolean) {
    if (reload) {
      // Send the event to the ShipArrivalView and reload the data with the selected filter
      this.$emit('dataChanged');
    }

    this.showDetailsDialog = false;
    this.showHistoryDialog = false;
  }

  public reloadAll() {
    this.$emit('dataChanged');
  }

  public requiredCarSlots(shipArrival: ShipArrival) {
    const carCount = shipArrival.carsImportVolume! + shipArrival.carsExportVolume!;
    return Math.ceil(carCount / process.env.VUE_APP_CAR_SLOT_VOLUME);
  }

  public requiredHeavySlots(shipArrival: ShipArrival) {
    const heavyCount = shipArrival.heavyImportVolume! + shipArrival.heavyExportVolume!;
    return Math.ceil(heavyCount / process.env.VUE_APP_HEAVY_SLOT_VOLUME);
  }

  public heavyBookedSlots(arrival: ShipArrival): number {
    if (!arrival.booking) {
      return 0;
    }
    const bookedSlots = (arrival.booking as Booking).bookedSlots;
    return bookedSlots.filter(slot => slot.slotType === SlotType.HEAVY)
      .reduce((sum: number, slot: Slot) => sum + slot.amount!, 0);
  }

  public carBookedSlots(arrival: ShipArrival): number {
    if (!arrival.booking) {
      return 0;
    }
    const bookedSlots = (arrival.booking as Booking).bookedSlots;
    return bookedSlots.filter(slot => slot.slotType === SlotType.CAR)
      .reduce((sum: number, slot: Slot) => sum + slot.amount!, 0);
  }

  public isEditable(shipArrival: ShipArrival): boolean {
    // Key_users are allowed to edit all the time
    if (this.$hasRole([UserRole.KEY_USER])) {
      return true;
    }

    let etaDate: DateTime;
    if (typeof (shipArrival.voyage as Voyage).eta === 'string') { // Sometimes there seem to be date objects?
      etaDate = DateTime.fromISO((shipArrival.voyage as Voyage).eta as string);
    } else {
      etaDate = DateTime.fromISO(((shipArrival.voyage as Voyage).eta as Date).toISOString())
    }

    // The editing is only allowed if the eta minus "locked amount days" is greater than the current date
    // e.g. ETA is 2022-01-19 14:00:00.000Z
    // locked amount days is 5 -> editing is allowed till the 2022-01-14 14:00:00.000Z
    const isInTimeRange = this.currentDateTime! <= etaDate.minus({ days: this.settings.lockedAmount });
    return isInTimeRange && (this.$hasRole([UserRole.PLANER, UserRole.IT_AUTOMOBILE]) ||
      (shipArrival.booking && (shipArrival.booking.status === BookingState.RESERVED
        || shipArrival.booking.status === BookingState.NO_BOOKING)) || !shipArrival.booking)
  }

  customSort(items: any[], index: string[], isDesc: boolean[]) {
    items.sort((a, b) => {
      if (index[0] === 'voyage.eta') {
        if (!isDesc[0]) {
          return a.voyage.eta - b.voyage.eta;
        } else {
          return b.voyage.eta - a.voyage.eta;
        }
      } else if (index[0] === 'voyage.vessel.name') {
        if (!isDesc[0]) {
          return a.voyage.vessel.name.toLowerCase().localeCompare(b.voyage.vessel.name.toLowerCase());
        } else {
          return b.voyage.vessel.name.toLowerCase().localeCompare(a.voyage.vessel.name.toLowerCase());
        }
      } else if (index[0] === 'voyage.voyageNoImport') {
        if (!isDesc[0]) {
          return a.voyage.voyageNoImport.toLowerCase().localeCompare(b.voyage.voyageNoImport.toLowerCase());
        } else {
          return b.voyage.voyageNoImport.toLowerCase().localeCompare(a.voyage.voyageNoImport.toLowerCase());
        }
      } else if (index[0] === 'voyage.voyageNoExport') {
        if (!isDesc[0]) {
          return a.voyage.voyageNoExport.toLowerCase().localeCompare(b.voyage.voyageNoExport.toLowerCase());
        } else {
          return b.voyage.voyageNoExport.toLowerCase().localeCompare(a.voyage.voyageNoExport.toLowerCase());
        }
      } else if (index[0] === 'shipOwner.name') {
        if (!a.shipOwner.name || !b.shipOwner.name) {
          return -1;
        }
        if (!isDesc[0]) {
          return a.shipOwner.name.toLowerCase().localeCompare(b.shipOwner.name.toLowerCase());
        } else {
          return b.shipOwner.name.toLowerCase().localeCompare(a.shipOwner.name.toLowerCase());
        }
      } else if (index[0] === 'import') {
        if (!isDesc[0]) {
          return a.carsImportVolume - b.carsImportVolume;
        } else {
          return b.carsImportVolume - a.carsImportVolume;
        }
      } else if (index[0] === 'export') {
        if (!isDesc[0]) {
          return a.carsExportVolume - b.carsExportVolume;
        } else {
          return b.carsExportVolume - a.carsExportVolume;
        }
      }
    });
    return items;
  }

  private get downloadOptions() {
    const fileTypes: any = this.$t('GENERAL.FILE_TYPES');
    return Object.keys(fileTypes).map(type => {
      return {
        text: fileTypes[type],
        value: type
      }
    });
  }

  private async downloadShipArrival(arrival: ShipArrival, fileType: string) {
    const uploadData = await shipArrivalRepository.download({arrival, fileType});
    const shipName = ((arrival.voyage as Voyage).vessel as Vessel).name;
    const date = this.$isoStringDateHumanized(((arrival.voyage as Voyage).eta as Date).toISOString())
    saveAs(uploadData.data, `${shipName}_${date}.${fileType.toLowerCase()}`)
  }
}

